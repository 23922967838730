import React from "react";
import PriceStartWidget from "./PriceCalculator/PriceStartWidget";
import PriceWidgetWindows from "./PriceCalculator/PriceWidgetWindows";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PriceWidgetThanks from "./PriceCalculator/PriceWidgetThanks";
import PriceCheckout from "./PriceCalculator/PriceCheckout";
import PriceWidgetWindowsSaver from "./PriceCalculator/PriceWidgetWindowsSaver";
import PriceWidgetPets from "./PriceCalculator/PriceWidgetPets";
import { PriceWidgetPanicButton } from "./PriceCalculator/PriceWidgetPanicButton";
import QueryString from "../helper/QueryString";
import PriceWidgetKfW from "./PriceCalculator/PriceWidgetKfW";
import PriceVorteile from "./PriceCalculator/elements/PriceVorteile";
import Cookies from "js-cookie";
import { isObject } from "util";
import PriceWidgetSecuritySystem from "./PriceCalculator/PriceWidgetSecuritySystem";
import PriceWidgetSmartphone from "./PriceCalculator/PriceWidgetSmartphone";
import PriceWidgetCCTV from "./PriceCalculator/PriceWidgetCCTV";
import PriceWidgetCCTVCount from "./PriceCalculator/PriceWidgetCCTVCount";
import PriceWidgetCCTVType from "./PriceCalculator/PriceWidgetCCTVType";
import PriceWidgetCCTVResolution from "./PriceCalculator/PriceWidgetCCTVResolution";
import { PriceWidgetFinance } from "./PriceCalculator/PriceWidgetFinance";
import PriceWidgetPostalCode from "./PriceCalculator/PriceWidgetPostalCode";
import PriceWidgetFloors from "./PriceCalculator/PriceWidgetFloors";
import PriceWidgetBudget from "./PriceCalculator/PriceWidgetBudget";
// fire alarm system questions :
import PriceStartWidgetFireAlarm from "./PriceCalculator/FireAlarmSystem/PriceStartWidgetFireAlarm";
import PriceWidgetFireAlarmAlreadyEquiped from "./PriceCalculator/FireAlarmSystem/PriceWidgetFireAlarmAlreadyEquiped";
import PriceWidgetFireAlarmFloors from "./PriceCalculator/FireAlarmSystem/PriceWidgetFireAlarmFloors";
import PriceWidgetFireAlarmRequirements from "./PriceCalculator/FireAlarmSystem/PriceWidgetFireAlarmRequirements";
import PriceWidgetFireAlarmCarryProcess from "./PriceCalculator/FireAlarmSystem/PriceWidgetFireAlarmCarryProcess";
import PriceWidgetFireAlarmRealEstateSize from "./PriceCalculator/FireAlarmSystem/PriceWidgetFireAlarmRealEstateSize";
// door lock system questions :
import PriceStartWidgetDoorLock from "./PriceCalculator/DoorLockSystem/PriceStartWidgetDoorLock";
import PriceWidgetDoorLockCount from "./PriceCalculator/DoorLockSystem/PriceWidgetDoorLockCount";
import PriceWidgetDoorLockUserCount from "./PriceCalculator/DoorLockSystem/PriceWidgetDoorLockUserCount";
import PriceWidgetDoorLockSmartphone from "./PriceCalculator/DoorLockSystem/PriceWidgetDoorLockSmartphone";

import {
    Videoueberwachung,
    Alarmanlage,
    Tuerschloss,
    Brand,
} from "./PriceCalculator/elements/Constants";

// video questions :
import PriceStartWidgetVideo from "./PriceCalculator/Video/PriceStartWidgetVideo";
import PriceWidgetVideoCCTV from "./PriceCalculator/Video/PriceWidgetVideoCCTV";
import PriceWidgetVideoCCTVCount from "./PriceCalculator/Video/PriceWidgetVideoCCTVCount";
import PriceWidgetVideoCCTVType from "./PriceCalculator/Video/PriceWidgetVideoCCTVType";
import PriceWidgetVideoCCTVResolution from "./PriceCalculator/Video/PriceWidgetVideoCCTVResolution";
import PriceWidgetVideoKI from "./PriceCalculator/Video/PriceWidgetVideoKI";
import PriceWidgetVideoSmartphone from "./PriceCalculator/Video/PriceWidgetVideoSmartphone";
import PriceWidgetVideoCrossSelling from "./PriceCalculator/Video/PriceWidgetVideoCrossSelling";

// alarm questions :
import PriceStartWidgetAlarm from "./PriceCalculator/Alarm/PriceStartWidgetAlarm";
import PriceWidgetAlarmFloors from "./PriceCalculator/Alarm/PriceWidgetAlarmFloors";
import PriceWidgetAlarmWindows from "./PriceCalculator/Alarm/PriceWidgetAlarmWindows";
import PriceWidgetAlarmPets from "./PriceCalculator/Alarm/PriceWidgetAlarmPets";
import PriceWidgetAlarmPanicButton from "./PriceCalculator/Alarm/PriceWidgetAlarmPanicButton";
import PriceWidgetAlarmSmartphone from "./PriceCalculator/Alarm/PriceWidgetAlarmSmartphone";
import PriceWidgetAlarmCrossSelling from "./PriceCalculator/Alarm/PriceWidgetAlarmCrossSelling";
import PriceCalcNavigation from "./PriceCalculator/PriceCalcNavigation";

require("es6-promise").polyfill();
require("isomorphic-fetch");
let slide = 0;

const pricesInitState = {
    objectType: null,
    contentWidgetStep: "main",
    floors: null,
    windows: 1,
    lastStep: ["main"],
    // showButtons: true,
    showButtons: true,
    showSkip: false,
    coronaBanner: true,
    showNextButton: false,
    showPrevButton: false,
    showBottomButtons: true,
    nextButtonStep: null,
    securitySystem: null,
    windows_saver: null,
    panic_button: null,
    cctv: null,
    cctv_count: 1,
    cctv_type: null,
    cctv_resolution: null,
    smartphone: null,
    kfw: null,
    finance: null,
    pets: null,
    fireAlarmAlreadyEquiped: null,
    fireAlarmFloors: 1,
    fireAlarmRealEstateSize: null,
    fireAlarmRequirements: null,
    fireAlarmCarryProcess: null,
    crossSelling: [],
};

const valueElements = [
    "objectType",
    "securitySystem",
    "floors",
    "windows",
    "windows_saver",
    "panic_button",
    "pets",
    "kfw",
    "cctv",
    "cctv_count",
    "cctv_type",
    "cctv_resolution",
    "smartphone",
    "finance",
    "fireAlarmAlreadyEquiped",
    "fireAlarmFloors",
    "fireAlarmRealEstateSize",
    "fireAlarmRequirements",
    "fireAlarmCarryProcess",
];

const PriceCalculatorComponents = {
    main: PriceStartWidget,
    securitySystem: PriceWidgetSecuritySystem,
    cctv: PriceWidgetCCTV,
    cctv_count: PriceWidgetCCTVCount,
    cctv_type: PriceWidgetCCTVType,
    cctv_resolution: PriceWidgetCCTVResolution,
    floors: PriceWidgetFloors,
    windows: PriceWidgetWindows,
    windows_saver: PriceWidgetWindowsSaver,
    pets: PriceWidgetPets,
    panic_button: PriceWidgetPanicButton,
    smartphone: PriceWidgetSmartphone,
    kfw: PriceWidgetKfW,
    finance: PriceWidgetFinance,
    postalCode: PriceWidgetPostalCode,
    budget: PriceWidgetBudget,
    checkout: PriceCheckout,
    thanks: PriceWidgetThanks,
    startAlarm: PriceStartWidgetAlarm,
    alarmFloors: PriceWidgetAlarmFloors,
    alarmWindows: PriceWidgetAlarmWindows,
    alarmPets: PriceWidgetAlarmPets,
    alarmPanicButton: PriceWidgetAlarmPanicButton,
    alarmSmartphone: PriceWidgetAlarmSmartphone,
    alarmCrossSelling: PriceWidgetAlarmCrossSelling,
    startVideo: PriceStartWidgetVideo,
    videoCctv: PriceWidgetVideoCCTV,
    videoCctvCount: PriceWidgetVideoCCTVCount,
    videoCctvType: PriceWidgetVideoCCTVType,
    videoCctvResolution: PriceWidgetVideoCCTVResolution,
    videoKI: PriceWidgetVideoKI,
    videoSmartphone: PriceWidgetVideoSmartphone,
    videoCrossSelling: PriceWidgetVideoCrossSelling,
    startFireAlarm: PriceStartWidgetFireAlarm,
    fireAlarmAlreadyEquiped: PriceWidgetFireAlarmAlreadyEquiped,
    fireAlarmFloors: PriceWidgetFireAlarmFloors,
    fireAlarmRealEstateSize: PriceWidgetFireAlarmRealEstateSize,
    fireAlarmRequirements: PriceWidgetFireAlarmRequirements,
    fireAlarmCarryProcess: PriceWidgetFireAlarmCarryProcess,
    startDoorLock: PriceStartWidgetDoorLock,
    doorLockCount: PriceWidgetDoorLockCount,
    doorLockUserCount: PriceWidgetDoorLockUserCount,
    doorLockSmartphone: PriceWidgetDoorLockSmartphone,
};

class PriceCalculator extends React.Component {
    constructor(props) {
        super(props);
        let prices = this.readState();

        if (this.isLastPage(prices)) {
            prices = this.resetState();
            this.updated([{ key: "contentWidgetStep", value: "thanks" }]);
        } else {
            prices = this.resetState(true);
        }
        this.state = prices;
        slide = 0;
    }

    componentWillMount() {
        switch (this.props.product) {
            case Videoueberwachung:
                this.updated([
                    { key: "lastStep", value: ["startVideo"] },
                    { key: "contentWidgetStep", value: "startVideo" },
                    { key: "securitySystem", value: Videoueberwachung },
                ]);
                break;

            case Alarmanlage:
                this.updated([
                    { key: "lastStep", value: ["startAlarm"] },
                    { key: "contentWidgetStep", value: "startAlarm" },
                    { key: "securitySystem", value: Alarmanlage },
                ]);
                break;
            case Tuerschloss:
                this.updated([
                    { key: "lastStep", value: ["startDoorLock"] },
                    { key: "contentWidgetStep", value: "startDoorLock" },
                    { key: "securitySystem", value: Tuerschloss },
                ]);
                break;
            case Brand:
                this.updated([
                    { key: "lastStep", value: ["startFireAlarm"] },
                    { key: "contentWidgetStep", value: "startFireAlarm" },
                    { key: "securitySystem", value: Brand },
                ]);
                break;
            default:
                this.updated([
                    { key: "lastStep", value: ["startAlarm"] },
                    { key: "contentWidgetStep", value: "startAlarm" },
                    { key: "securitySystem", value: Alarmanlage },
                ]);
                break;
        }
    }

    componentDidUpdate() {
        this.props.handleNewQuestionInHero();
    }
    componentDidMount() {
        setTimeout(() => {
            if (typeof window !== `undefined`) {
                let path = window.location.pathname;
                let path2 = path.split("/preisrechner");
                console.log("path2 :", path2);
                path = path.replace(/\//g, "");
                console.log("path isss", path);
                if (path2.length == 2) {
                    // marginTop = "3rem";
                    let element = document.getElementById(
                        "price-calculator-columns-id"
                    );
                    element.style.marginTop = "3rem";
                }
            }
        }, 100);
    }
    componentWillUnmount() {
        console.log("componentWillUnmount fired in priceCalculater");
        slide = 0;
    }
    render() {
        let {
            lastStep,
            showNextButton,
            nextButtonStep,
            showBottomButtons,
            showButtons,
            coronaBanner,
            showSkip,
            contentWidgetStep,
        } = this.state;
        let showNavigation = this.props.showNavigation;
        let marginTop;

        let path = "";
        let name = "Alarmanlagen";
        if (typeof window !== "undefined" && typeof document !== "undefined") {
            path = window.location.pathname;
            path = path.replace(/\//g, "");
        }
        if (path.includes("schliessanlagen")) {
            name = "Schließanlagen";
        }
        if (path.includes("videoueberwachung")) {
            name = "Videoanlagen";
        }

        return (
            <div
                className="price-calculator-columns"
                // style={{ marginTop: marginTop }}
                id="price-calculator-columns-id"
            >
                <div
                    className="box price-box is-half is-offset-one-quarter"
                    style={{ overflow: "hidden" }}
                    id="boxCalc"
                >
                    <h4
                        className="only-mobile-500px-Max"
                        id="only-mobile-500px-Max"
                    ></h4>

                    <article className="media price-calculator">
                        <div className="media-content">
                            {/* question - {slide / 2} */}
                            {getContent(this.state, this.updated.bind(this))}
                        </div>
                    </article>
                    {/* Corona Banner Mobile */}
                    {coronaBanner && (
                        <div
                            className="columns is-mobile is-hidden-tablet is-vcentered"
                            style={{
                                backgroundColor: "#f2f2f2",
                                margin: "0",
                                marginTop: "12px",
                                borderRadius: "5px",
                            }}
                        >
                            <div
                                className="column is-narrow"
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    paddingRight: "0px",
                                    paddingLeft: "20px",
                                }}
                            >
                                <i
                                    className="fas fa-piggy-bank left is-size-3"
                                    style={{
                                        color: "#1895ff",
                                        verticalAlign: "middle",
                                    }}
                                ></i>
                            </div>
                            <div
                                className="column is-size-7"
                                style={{ paddingLeft: "0px" }}
                            >
                                Jetzt noch für kurze Zeit:{" "}
                                <strong>-10% Sommer-Rabatt</strong> auf unsere
                                Sicherheitstechnik
                            </div>
                        </div>
                    )}
                    {showButtons && (
                        // pricesInitState.contentWidgetStep != "main" &&
                        <span id="price-footer2">
                            <div
                                className="columns is-vcentered price-footer"
                                id="price-footer"
                                style={{
                                    display: showBottomButtons
                                        ? "flex"
                                        : "none",
                                    marginTop:
                                        typeof window != "undefined" &&
                                        window.innerWidth > 640
                                            ? "20px"
                                            : "0",
                                }}
                            >
                                <div className="column is-narrow">
                                    <button
                                        className="button button-left is-pulled-left"
                                        disabled={!(lastStep.length > 1)}
                                        onClick={() => {
                                            lastStep.pop();
                                            this.updated([
                                                {
                                                    key: "lastStep",
                                                    value: lastStep,
                                                },
                                            ]);
                                        }}
                                    >
                                        <i className="fas fa-arrow-left"></i>
                                    </button>
                                </div>
                                {/* Corona Banner Desktop */}
                                {coronaBanner && (
                                    <div className="column is-hidden-mobile">
                                        <div
                                            className="columns is-vcentered"
                                            style={{
                                                backgroundColor: "#f2f2f2",
                                                margin: "0",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div
                                                className="column is-narrow"
                                                style={{
                                                    paddingTop: "0px",
                                                    paddingBottom: "0px",
                                                    paddingRight: "0px",
                                                    paddingLeft: "10px",
                                                }}
                                            >
                                                <i
                                                    className="fas fa-piggy-bank left is-size-4"
                                                    style={{
                                                        color: "#1895ff",
                                                        verticalAlign: "middle",
                                                    }}
                                                ></i>
                                            </div>
                                            <div
                                                className="column"
                                                style={{
                                                    paddingLeft: "0px",
                                                    fontSize: "0.9rem",
                                                }}
                                            >
                                                Jetzt noch für kurze Zeit:{" "}
                                                <strong>
                                                    -10% Sommer-Rabatt
                                                </strong>{" "}
                                                auf unsere Sicherheitstechnik
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!coronaBanner && (
                                    <div className="column"></div>
                                )}
                                {showSkip && (
                                    <div className="column is-narrow">
                                        <button
                                            className="button button-skip"
                                            disabled={!(lastStep.length > 1)}
                                            onClick={() => {
                                                lastStep.pop();
                                                this.updated([
                                                    {
                                                        key: "contentWidgetStep",
                                                        value: nextButtonStep,
                                                    },
                                                    {
                                                        key: "showNextButton",
                                                        value: false,
                                                    },
                                                ]);
                                            }}
                                        >
                                            Überspingen
                                        </button>
                                    </div>
                                )}
                                <div className="column is-narrow">
                                    <button
                                        className="button button-right"
                                        disabled={
                                            !(showNextButton && nextButtonStep)
                                        }
                                        onClick={() => {
                                            this.updated([
                                                {
                                                    key: "contentWidgetStep",
                                                    value: nextButtonStep,
                                                },
                                                {
                                                    key: "showNextButton",
                                                    value: false,
                                                },
                                            ]);
                                        }}
                                    >
                                        Weiter
                                    </button>
                                </div>
                            </div>
                        </span>
                    )}
                    {!showButtons && (
                        <div
                            className="columns price-footer"
                            style={{
                                display: showBottomButtons ? "flex" : "none",
                            }}
                        ></div>
                    )}
                    {showBottomButtons && (
                        <PriceVorteile
                            name="vorteil"
                            mainStyle=""
                            subStyle=""
                        />
                    )}
                    {showNavigation && (
                        <div className="is-hidden-tablet">
                            <PriceCalcNavigation product={this.props.product} />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    readState() {
        let prices = Cookies.getJSON("state");
        if (!isObject(prices)) {
            prices = pricesInitState;
        }

        return prices;
    }

    resetState(keepValues = false) {
        let resetedPrices = pricesInitState;
        if (keepValues) {
            let prices = this.readState();
            for (let i = 0; i < valueElements.length; i++) {
                resetedPrices[valueElements[i]] = prices[valueElements[i]];
            }
            Cookies.set("state", resetedPrices);
        } else {
            Cookies.remove("state");
        }

        return resetedPrices;
    }

    writeState(prices) {
        Cookies.set("state", prices);
        this.setState(prices);
    }

    updatePage(prices, newPage) {
        if (prices.lastStep.indexOf(newPage) === -1) {
            prices.lastStep.push(newPage);
        }

        return prices;
    }

    isLastPage(prices) {
        return prices.lastStep[prices.lastStep.length - 1] === "thanks";
    }

    updated(data) {
        let prices = this.readState();
        let contentWidgetStep = null;
        let sendEmail = false;
        data.forEach((element) => {
            prices[element.key] = element.value;
            switch (element.key) {
                case "contentWidgetStep":
                    contentWidgetStep = element.value;
                    break;
                case "sendEmail":
                    sendEmail = element.value;
                    break;
                default:
                    break;
            }
        });
        if (contentWidgetStep !== null) {
            prices = this.updatePage(prices, contentWidgetStep);
        }
        this.writeState(Object.assign(this.readState(), prices));
        if (sendEmail) {
            this.sendEmail();
        }
    }

    sendEmail() {
        let prices = this.readState();
        let query = new QueryString();
        console.log("query from calc ", query);
        switch (prices.securitySystem) {
            case "Einbruch":
                prices.cctv_count = null;
                break;
            case "Video":
                prices.windows = null;
                break;
            default:
                break;
        }
        Object.keys(prices).forEach((key) => {
            if (prices[key] === null || prices[key] === "") {
                prices[key] = "ungefragt";
            }
        });
        let body = Object.assign(prices, query.getQuery());
        if (
            prices.firstName === "2c08d52c-ad14-11e8-98d0-529269fb1459" &&
            prices.lastName === "d48f9d07-46d4-41a3-8ce8-4e0351756950"
        ) {
            prices = this.updatePage(prices, "thanks");
            this.writeState(Object.assign(this.readState(), prices));
            this.resetState();
        } else {
            let userTrack = {};

            if (typeof window !== "undefined") {
                userTrack = JSON.parse(localStorage.getItem("userTrack")) ?? {
                    usertype: 0,
                };
            }

            body.IsChatbotVisible__c = Boolean(userTrack.usertype % 2 !== 0);
            // body.IsChatbotVisible__c = false;
            fetch(process.env.GATSBY_API_URL + "/checkout", {
                // fetch(`https://api.ma-development.de` + "/checkout", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            }).then((response) => {
                prices = this.updatePage(prices, "thanks");
                this.writeState(Object.assign(this.readState(), prices));
                this.resetState();
            });
        }
    }
}
function getContent(prices, updated) {
    console.log("prices getContent : ", prices);
    prices.contentWidgetStep = prices.lastStep[prices.lastStep.length - 1];
    if (
        typeof window != `undefined` &&
        slide == 0 &&
        window.innerWidth <= 768
    ) {
        setTimeout(() => {
            let priceFooter = document.getElementById("price-footer");
            console.log("priceFooter fired", priceFooter);
            priceFooter.style.display = "none";
        }, 100);
    } else {
        setTimeout(() => {
            if (
                typeof window != `undefined` &&
                document.getElementById("price-footer")
            ) {
                let priceFooter = document.getElementById("price-footer");
                priceFooter.style.display = "flex";
            }
        }, 100);
    }
    slide = slide + 1;
    if (prices.contentWidgetStep !== ("checkout" || "postalCode")) {
        if (typeof window != "undefined" && window.innerWidth > 769) {
            scrollToTop();
        }
    }
    return React.createElement(
        PriceCalculatorComponents[prices.contentWidgetStep],
        { prices, updated, key: prices.contentWidgetStep }
    );
}

function scrollToTop() {
    if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
    }
}

export default PriceCalculator;
